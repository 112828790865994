import * as React from "react"
import '../styles/global.css';
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"

function Card() {
  return (<div className="relative group w-full max-w-xs sm:w-80 mt-6 sm:mt-20 sm:p-2">
    <div
      className="absolute hidden sm:block p-8 bg-gray-500 opacity-50 dark:bg-gray-200 w-full h-[calc(100%-1rem)] rounded-lg transition duration-150 rotate-3 group-hover:rotate-6" />
    <div className="relative z-10 p-8 bg-gray-900 dark:bg-gray-100 dark:text-gray-700 text-white w-full rounded-lg group-hover:shadow-lg">
      <div className="flex justify-around">
        <StaticImage className="w-24 h-24 rounded-full overflow-hidden" src="../images/me.png" alt="A photo of me" height={96} width={96} />
      </div>
      <h1 className="text-2xl text-center">Thomas Schiet</h1>
      <h2 className="text-center">Senior software engineer</h2>
      <div className="mt-6">
        <h3 className="font-medium">Interests</h3>
        <ul className="">
          <li>Web development</li>
          <li>Security</li>
          <li>PostgreSQL</li>
          <li>Accessibility</li>
          <li>Algorithmic design</li>
          <li>Machine learning</li>
        </ul>
      </div>
    </div>
  </div>);
}

const LinkedInLogo = () => (<svg className="h-6 w-6 group-hover:scale-105 transform group-hover:-translate-y-1 duration-100" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path></svg>);

const IndexPage = () => {
  return (
    <main className="">
      <Helmet htmlAttributes={{
        lang: 'en',
      }} bodyAttributes={{
        class: "dark:bg-gray-800 bg-white font-sans dark:text-white antialiased"
      }}>
        <title>Thomas Schiet</title>
        <meta charSet="utf-8" />
      </Helmet>
      <div className="flex items-center flex-col">{Card()}</div>
      <section>
        <div className="p-4 mx-auto max-w-md">
          <h1 className="text-4xl font-display">About me</h1>
          <p>
            Co-founder and CTO @ <a className="underline focus-visible:ring focus-visible:outline-none ring-offset-2 ring-offset-gray-800 rounded ring-white" href="https://joinflux.nl" target="_blank" rel="nofollow noreferrer">Flux Medical Systems</a>
          </p>
          <p>Previously contractor at Ticketmatic and others.</p>
        </div>
        <div className="mt-4 flex justify-center items-center space-x-2">
          <div className="group">
            <a
              href="mailto:thomas.schiet@gmail.com"
              className="px-6 py-4 bg-red-600 rounded-xl inline-block focus-visible:ring focus-visible:outline-none ring-red-500 ring-offset-4 ring-offset-gray-800 font-medium text-white group-hover:scale-105 transform group-hover:-translate-y-1 duration-100"
            >Contact me</a>
          </div>
          <div className="group">
            <a href="https://www.linkedin.com/in/thomas-schiet-748a17101/" target="_blank" rel="nofollow noreferrer">
              <LinkedInLogo></LinkedInLogo>
            </a>
          </div>
        </div>
      </section>
    </main>
  )
}

export default IndexPage
